import { useCallback } from 'react'

import { KeyboardArrowUp } from '@mui/icons-material'
import type { Theme } from '@mui/material'
import { Box, Fab, useMediaQuery, Zoom } from '@mui/material'
import useScrollTrigger from '@mui/material/useScrollTrigger'

import { STICKY_DOWNLOAD_BUTTON_PAPER_HEIGHT } from '@/constants'
import { getSelectedAssets, useCartContext } from '@/contexts/CartContext'

const dataCy = 'ScrollTop'

const ScrollTop = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })

  const { state } = useCartContext()
  const assets = getSelectedAssets(state)

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))

  return (
    <Zoom in={trigger}>
      <Box
        role="presentation"
        sx={{
          position: 'fixed',
          bottom:
            // we move the button a little bit higher in nondesktop so it doesn't collide with download button
            32 + (!isDesktop && assets.length > 0 ? STICKY_DOWNLOAD_BUTTON_PAPER_HEIGHT : 0),
          right: 32,
          zIndex: 1000, // on mobile, filters have z-index: 1200 and we don't want to have this button appear on top of filters
        }}
      >
        <Fab
          onClick={scrollToTop}
          color="secondary"
          size="small"
          aria-label="scroll back to top"
          data-cy={dataCy}
        >
          <KeyboardArrowUp />
        </Fab>
      </Box>
    </Zoom>
  )
}

export default ScrollTop
